import React from "react";
import PropTypes from "prop-types";

import {
    AsbestosSurveySelectMenu,
} from "../../../../../../formGroups/PropertyDetailsGroup";

export default function AsbestosSurveyServiceSelect({ control, register }) {
  return (
    <>
      <AsbestosSurveySelectMenu control={control} required={true} register={register} />
    </>
  );
}

// PropTypes
AsbestosSurveyServiceSelect.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};
